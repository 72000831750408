import { ProductOrder } from '../models/product-order';

type matomoEvent = [category: string, action?: string, name?: string, value?: number, data?: any]

export class Tracker {
  constructor() {
  }

  /**
   * Direct _paq access to tracker push
   */
  push(value){
    window._paq.push(value);
  }

  event(...args: matomoEvent) {
    window._paq.push(['trackEvent', ...args]);
  }

  subscriptionConfirm(order: ProductOrder) {
    window._paq.push(['addEcommerceItem',
      order.product.id,
      order.product.name,
      'Subscription',
      order.product.price,
      1,
    ]);

    window._paq.push(['trackEcommerceOrder',
      // (Required) orderId
      order.id,
      // (Required) grandTotal (revenue)
      order.product.price,
    ]);
  }
}
