import { catchError, map, Observable, of } from 'rxjs';
import { FormValidation } from '../form-validation';
import { HttpRequestService } from '../services/http-request.service';

/**
 * Handling the status of the form and the sending of requests
 */
export class FormController {
  formValidation = new FormValidation();
  form: HTMLFormElement;
  constraints: any;
  button: HTMLButtonElement;

  constructor(form: HTMLFormElement, constraints: any) {
    this.form = form;
    this.constraints = constraints;
    this.button = this.form?.getElementsByTagName('button')?.item(0);
  }

  /**
   * Check if form is valid according to constraints of the form (mandatory fields...)
   */
  isFormValid(): boolean {
    this.formValidation.resetFormGroup(this.form);
    this.formValidation.setForm(this.form, this.constraints);
    return this.formValidation.isValid();
  }

  /**
   * Send request and display success or error message according to the status of the request
   */
  sendRequest(url: string, body: object, successMessage: string, errorMessage: string, apiKey?: string): Observable<any> {
    this.addLoader();
    const request = new HttpRequestService();
    return request.request(url, 'POST', apiKey, body).pipe(
      map((response) => {
        this.resetErrors();
        this.formValidation.addMessage(this.form, 'success', successMessage, `success`);
        return { status: response.status };
      }),
      catchError((error) => {
        this.resetErrors();
        if (error.status === 400) {
          this.formValidation.addMessage(this.form, 'error', errorMessage, `${error.name}-error`);
        } else {
          // TODO: messages should be present in HTML and displayed by a code
          this.formValidation.addMessage(this.form, 'error', 'Unknown error', 'unknown-error');
        }
        return of({ status: error.status, response: error.response });
      }),
    );
  }

  /**
   * Reset form errors and remove loader
   */
  private resetErrors() {
    this.formValidation.resetFormGroup(this.form);
    this.removeLoader();
  }

  /**
   * Add loader to the form and disable the form button
   */
  addLoader() {
    this.button.classList.add('loading');
    this.button.setAttribute('disabled', 'disabled');
  }

  /**
   * Remove the loader and remove disable status of the submit form button
   */
  removeLoader() {
    this.button.classList.remove('loading');
    this.button.removeAttribute('disabled');
  }
}
