import { Modal } from 'bootstrap';

export class ModalController {
  private readonly modalEl: HTMLElement;
  private modalTitle: HTMLElement;
  private modalBody: HTMLElement;
  modal: Modal;

  constructor(title, content, config?: Partial<Modal.Options>) {
    this.modalEl = document.getElementById('customModal');

    if (this.modalEl) {
      this.modal = Modal.getOrCreateInstance(this.modalEl, config);
      this.modalTitle = this.modalEl.querySelector('.modal-title');
      this.modalBody = this.modalEl.querySelector('.modal-body');
      this.setTitle(title)
      this.setContent(content);
    }
  }

  setTitle(title: string) {
    this.modalTitle.textContent = title;
  }

  setContent(content: string) {
    this.modalBody.innerHTML = content;
  }
}
