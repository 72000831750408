import { Product } from '../models/product';
import { UrlHandler } from '../url-handler';
import { UserInterface } from '../interfaces/user.interface';
import { RegisterForm } from './register-form.controller';
import { ProductsEnum } from '../enums/products.enum';
import ArtraApp from '../artra-app';
import { SubscriptionService } from '../services/subscription.service';
import { ModalController } from './modal.controller';
import { ProductOrder } from '../models/product-order';

const nunjucks = require('nunjucks');

export class ProductsController {
  /**
   * Stripe service to handle subscription
   */
  subscriptionService: SubscriptionService;
  /**
   * User of plone
   */
  user: UserInterface;

  planSelectItems: NodeListOf<HTMLElement>;
  /**
   * Modal which display confirmation message after purchase
   */
  purchaseConfirmModal: ModalController;
  private form: RegisterForm | undefined;
  private selectedPlan: Product | undefined;

  constructor(private artraApp: ArtraApp) {
    this.subscriptionService = new SubscriptionService(artraApp);
    this.getProducts();
    this.displayPurchaseConfirmMessage();
  }

  /**
   * Get all products and prices from stripe
   */
  private getProducts() {
    this.subscriptionService.getPlansList().subscribe((products) => {
      this.displayProducts(products);
    });
  }

  /**
   * Display products on the homepage with Nunjucks
   */
  private displayProducts(products: Product[]) {
    nunjucks.configure(`../${this.artraApp.lang}`);
    nunjucks.render('products.html', {
      products: products,
      lang: this.artraApp.lang,
    }, (err, products) => {
      const productContainer = document.getElementById('products');
      if (productContainer) {
        productContainer.innerHTML = products;
        this.planSelectItems = document.querySelectorAll('[data-plan]');
        window.artra.clicks.register('plan-select', this.onPlanItemClick.bind(this));
        this.setManagerLinkHref();
      }
    });
  }

  private onPlanItemClick(event: Event, target: HTMLElement) {
    event.preventDefault();
    const planName: string = target.dataset.plan;
    if (planName) {
      this.selectPlan(planName);
    } else {
      console.error('Plan name to select is undefined.');
    }
  }

  /**
   * Select plan item matching provided plan name
   */
  private selectPlan(planName: string) {
    this.artraApp.track.event('Subscription', 'plan-select', planName);
    if (planName === ProductsEnum.Custom) {
      const email = this.artraApp.config.contactEmail.replace('&at;', '@');
      window.location.href = `mailto:${email}`;
    } else {
      this.selectedPlan = this.subscriptionService.getPlanByName(planName);
      if (this.selectedPlan) {
        this.displayForm();
      }
    }


    this.planSelectItems.forEach((element) => {
      if (element.dataset.plan === planName) {
        element.classList.add('active');
      } else {
        element.classList.remove('active');
      }
    });
  }

  /**
   * Display form to register the user
   */
  displayForm() {
    if (!this.form) {
      const formElement: HTMLFormElement | null = document.querySelector('#register-form');
      this.form = new RegisterForm(formElement);
      this.form.onSubmit$.subscribe((data) => {
        this.form.setLoading(true);
        this.form.resetErrors();
        this.artraApp.track.event('Subscription', 'form-submit', this.selectedPlan.name);
        this.subscriptionService.createAccount(this.selectedPlan, data.email, data.gallery_name).subscribe({
          next: ({ url, order }) => {
            this.form.resetErrors();
            this.form.setLoading(false);
            this.artraApp.track.event('Subscription', 'redirect-to-stripe');
            window.location.assign(url);
          },
          error: (err) => {
            this.form.setLoading(false);
            console.error(err.message, err);
            if (err.status === 400) {
              this.artraApp.track.event('Subscription', 'form-error', 'existent-user');
              this.form.setError('existent-user');
            } else {
              this.artraApp.track.event('Subscription', 'form-error', 'code-error', err.status);
              this.form.setError('server-error');
            }
          },
        });
      });
    }
    if (!document.body.classList.contains('aside-open')) {
      document.body.classList.add('aside-open');
      this.artraApp.track.event('Subscription', 'form-panel-open');
    }
  }

  /**
   * Display a message in modal according to the state of its purchase
   */
  private displayPurchaseConfirmMessage() {
    const hasPurchaseParam = UrlHandler.hasParam('completed');
    const hasCancelParam = UrlHandler.hasParam('aborted');
    const order = this.subscriptionService.restoreOrder();

    // no order started
    if (!order) {
      return;
    }
    // user press navigates back from stripe page
    if (order && !hasPurchaseParam && !hasCancelParam) {
      this.artraApp.track.event('Subscription', 'leave-stripe', 'navigate-back');
      this.displayAbortModal(order);
      return;
    }

    if (hasPurchaseParam) {
      this.artraApp.track.event('Subscription', 'leave-stripe', 'purchase-complete');
      this.artraApp.track.subscriptionConfirm(order);
      this.displaySuccessModal(order);
      this.subscriptionService.clearOrder();
      return;
    }
    if (hasCancelParam) {
      this.artraApp.track.event('Subscription', 'leave-stripe', 'click-back-to-artra-logo');
      this.displayAbortModal(order);
    }
  }

  /**
   * TODO: move text to HTML
   */
  private displayAbortModal(order: ProductOrder) {
    const modalTitle = this.artraApp.lang === 'fr' ? 'Terminer l’inscription' : 'Complete registration';
    const proceedButtonText = {
      fr: 'Procéder au paiement',
      en: 'Proceed to payment',
    };
    const activateFreePlan = {
      fr: 'Démarrer la période d\'essai',
      en: 'Start trial period',
    };
    const getButtonText = (product: Product) => {
      if (product.name === 'Free' || product.price === 0) {
        return activateFreePlan[this.artraApp.lang];
      }
      return proceedButtonText[this.artraApp.lang];
    };
    const modalContent = this.artraApp.lang === 'fr' ?
      `<p>Vous n'avez pas terminé votre inscription au plan <strong class="text-bolder text-big text-secondary">${order.product.name}</strong>.</p>
      <p>
        <button class="btn btn-success btn-uppercase" type="button" onclick="window.gotoStripe()" data-goto-stripe>${getButtonText(order.product)}</button>
        <button class="btn btn-link" type="button" onclick="window.cancelSubscribe()" data-cancel-sub>Annuler</button>
      </p>
      <p class="text-muted text-smaller">Votre compte sur le manager de catalogues a cependant été créé avec le nom d'utilisateur suivant&nbsp;:
        <span class="text-code text-bold">${order.user.email}</span><br>
        <span>Il sera actif après avoir configuré le mot de passe via le lien présent dans l'email que vous venez de recevoir.</span>
      </p>
      `
      : `<p>You have not completed your subscription to the <strong class="text-bolder text-big text-secondary">${order.product.name}</strong> plan.</p>
        <p>
          <button class="btn btn-success btn-uppercase" type="button" onclick="window.gotoStripe()" data-goto-stripe>${getButtonText(order.product)}</button>
          <button class="btn btn-link" type="button" onclick="window.cancelSubscribe()" data-cancel-sub>Cancel</button>
        </p>
        <p class="text-muted text-smaller">However, your catalog manager account was created with the following username:
          <span class="text-code text-bold">${order.user.email}</span><br>
          <span>It will be active after configuring the password via the link in the email you just received.</span>
        </p>
      `;

    window['cancelSubscribe'] = () => {
      this.purchaseConfirmModal.modal.hide();
      this.subscriptionService.clearOrder();
    };

    window['gotoStripe'] = () => {
      window.location.assign(order.url);
    };

    this.purchaseConfirmModal = new ModalController(modalTitle, modalContent, {
      keyboard: false,
      backdrop: 'static',
    });
    this.purchaseConfirmModal.modal.show();
    this.artraApp.track.event('Subscription', 'abort-message-displayed');
  }

  /**
   * TODO: move text to HTML
   */
  private displaySuccessModal(order: ProductOrder) {
    const modalTitle = this.artraApp.lang === 'fr' ? 'Confirmation' : 'Success';
    let modalContent = this.artraApp.lang === 'fr'
      ? `Merci pour votre inscription. Pour commencer à encoder vos œuvres, merci de suivre le lien présent dans l’email de configuration de mot de passe.<br><br> Votre identifiant : <span>${order.user.email}</span>`
      : `Thank you for your registration. To start uploading your artworks, please follow the link in the password configuration email.<br><br> Your username: <span>${order.user.email}</span>`;
    modalContent += `<div class="mt-4" data-bs-dismiss="modal"><button class="btn btn-primary" type="button">Ok</button></div>`

    this.purchaseConfirmModal = new ModalController(modalTitle, modalContent);
    this.purchaseConfirmModal.modal.show();
    this.artraApp.track.event('Subscription', 'confirm-message-displayed');
  }

  /**
   * Handle subscription by clicking on the link below the plans
   */
  private setManagerLinkHref() {
    const managerUrl = `${this.artraApp.config.ploneUrl}manage-subscription?set_language=${this.artraApp.lang}`;
    const managerLink = document.getElementById('handle-subscription');
    managerLink?.setAttribute('href', managerUrl);
  }
}
