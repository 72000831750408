import { catchError, Observable, throwError } from 'rxjs';
import { UserInterface } from '../interfaces/user.interface';
import ArtraApp from '../artra-app';
import { AjaxError } from 'rxjs/internal/ajax/errors';
import { HttpClient } from './httpClient';
import { CatalogInterface } from '../interfaces/catalog.interface';

export class PloneService {
  /**
   * Base url for Plone files to get them
   */
  fileUrl: string;
  /**
   * Base url or registration of Plone
   */
  registerUrl: string;

  constructor(private artraApp: ArtraApp) {
    this.registerUrl = this.getPloneUrlForRoute('@users');
    this.fileUrl = artraApp.config.ploneFileUrl;
  }

  static getPloneRouteUrl(ploneUrl: string, route: string): string {
    const base = ploneUrl.replace(/\/$/, '');
    const unslashedRoute = route.replace(/^\//, '');
    return `${base}/${unslashedRoute}`;
  }

  getPloneUrlForRoute(route: string): string {
    return PloneService.getPloneRouteUrl(this.artraApp.config.ploneUrl, route);
  }

  /**
   * Get catalogs of Plone
   */
  getCatalogs() {
    const url = `${this.fileUrl}galleries.json`;
    return HttpClient.get<{ galleries: CatalogInterface[]; }>(url);
  }

  /**
   * Register catalogs to Plone
   * Add the registered user to a cookie to connect him
   */
  registerUser(email: string, galleryName: string, lang: string): Observable<UserInterface> {
    return HttpClient.post<UserInterface>(this.registerUrl, {
      email,
      gallery_name: galleryName,
      lang,
    }).pipe(
      catchError((err: AjaxError) => {
        err.message = 'PloneUserCreationError';
        return throwError(() => err);
      })
    )
  }
}
