import ArtraApp from './assets/ts/artra-app';
import { HeaderController } from './assets/ts/controllers/header.controller';
import { ProductsController } from './assets/ts/controllers/products.controller';
import { CatalogsController } from './assets/ts/controllers/catalogs.controller';
import { LanguageMenuHandler } from './assets/ts/controllers/language-menu.controller';
import { NewsletterForm } from './assets/ts/controllers/newsletter-form.controller';
import { ConsentCookies } from './assets/ts/cookieconsent';

// ASSETS
import './assets/pdf/damier.pdf';

/**
 * JS executed on runtime
 * TODO: rewrite
 */
import './assets/js/mobile-nav';
// index only
import './assets/js/before-after-picture';
import './assets/js/slider';

/**
 * MAIN APP CONTROLLER
 */

const artra = new ArtraApp(window.appConfig, window.env);
window['artra'] = artra;

(() => {
  new ConsentCookies();
  new HeaderController();
  new ProductsController(artra);
  new CatalogsController(artra);
  new LanguageMenuHandler();
  new NewsletterForm(artra);
})();
