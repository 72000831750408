enum OptionStatus {
  Disable = 'disable',
  Disabled = 'disabled',
  Checked = 'true',
  Unchecked = 'false',
}

export class ProductOption {
  private optionProperties = ['status', 'fr', 'en'];
  label: string;
  labelI18n: {
    fr: string;
    en: string;
  };
  checked: boolean;
  enabled: boolean;

  constructor(data: string, public order: number, private locale: string) {
    let values = data.trim().replace(/;$/, '').split(';');
    if (values.length != this.optionProperties.length) {
      console.error(`Invalid stripe product option data provided.
      Please use ";" as separator and provide ${this.optionProperties.length} values.
      Examples:
      - checked: "${OptionStatus.Checked}; français; English"
      - unchecked: "${OptionStatus.Unchecked}; français; English"
      - hidden: "${OptionStatus.Disable}; français; English"
      Provided data: "${data}"`);
      values = [OptionStatus.Disable, '', ''];
    }
    this.valuesToOption(values);
  }

  private valuesToOption(values: string[]) {
    const props = this.mapValueToKeys(values);
    this.label = props[this.locale] || props['en'];
    this.labelI18n = {
      fr: props['fr'],
      en: props['en'],
    };
    this.setStatus(props['status']);
  }

  private mapValueToKeys(values: string[]): Record<string, any> {
    return values.reduce((accum, value, index) => ({
      ...accum,
      [this.optionProperties[index]]: value.trim(),
    }), {});
  }

  private setStatus(value: OptionStatus) {
    this.enabled = value !== OptionStatus.Disable && value !== OptionStatus.Disabled;
    this.checked = value === OptionStatus.Checked;
  }
}
