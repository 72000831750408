export class UrlHandler {
  static getParam(param: string): string {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  }

  static hasParam(param: string) {
    return UrlHandler.getParam(param) !== null;
  }
}
