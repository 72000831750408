export class LanguageMenuHandler {
  languageLinks: NodeListOf<Element>;
  pathArray: string[];
  currentLanguage: string;
  currentUrl: string;

  constructor() {
    this.init();
  }

  init() {
    this.languageLinks = document.querySelectorAll('.language-link');
    this.pathArray = window.location.pathname.split('/');
    this.currentLanguage = window.lang;
    document.getElementById('dropdown-current-language').innerHTML = this.currentLanguage;
    this.bindLanguageClick();
  }

  bindLanguageClick() {
    this.languageLinks?.forEach((languageLink) => {
      languageLink.addEventListener('click', () => {
        this.currentUrl = window.location.href;
        const newLanguage = languageLink.id;
        this.goToNewLanguage(newLanguage);
      });
    });
  }

  goToNewLanguage(newLanguage: string) {
    if (this.currentLanguage) {
      window.location.href = this.currentUrl.replace(this.currentLanguage, newLanguage);
    } else {
      window.location.href = this.currentUrl + newLanguage;
    }
  }
}

