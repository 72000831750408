import { DocumentClickHandler } from './document-click-handler';
import { Device } from './device-detection';
import { AppConfig } from './interfaces/app-config.interface';
import { Tracker } from './services/tracker.service';

export default class ArtraApp {
  clicks: DocumentClickHandler;
  track: Tracker;

  constructor(public config: AppConfig, private envName: string) {
    this.clicks = new DocumentClickHandler();
    this.track = new Tracker();
    this.init();
  }

  get lang(): string {
    return window.lang;
  }

  get isProd(): boolean {
    return this.env === 'prod';
  }

  get env(): string {
    return this.envName;
  }

  init() {
    /**
     * Add body classe for detected device (phone, table, desktop)
     */
    Device.addBodyClasses();

    /**
     * Register mailto link handler
     */
    this.clicks.register('mailto', (event, target) => {
      event.preventDefault();
      const email = target.dataset.mailto || this.config.contactEmail;
      window.open(`mailto:${email.replace('&at;', '@')}`, '_self');
    });
  }
}
