import { FormValidation } from '../form-validation';
import { fromEvent } from 'rxjs';
import { FormController } from './form.controller';
import ArtraApp from '../artra-app';
import { PloneService } from '../services/plone.service';

export class NewsletterForm {
  formValidation = new FormValidation();
  newsletterForm = document.getElementById('newsletter') as HTMLFormElement;
  url: string;
  errorMessage = 'Vous êtes déjà inscrit à notre newsletter';
  successMessage = 'Merci pour votre inscription à notre newsletter !\n';
  // id of the list of contacts subscribed to the French newsletter
  listIds = [5];
  // id of the template of the French email sent when the registration is done
  templateId = 6;
  constraints = {
    email: this.formValidation.emailConstraint,
    rgpd: this.formValidation.rgpdConstraint,
  };
  formController = new FormController(this.newsletterForm, this.constraints);

  constructor(private artraApp: ArtraApp) {
    this.url = PloneService.getPloneRouteUrl(artraApp.config.ploneUrl, '@contact');
    this.initForm();
  }

  private initForm() {
    if (this.artraApp.lang === 'en') {
      this.errorMessage = 'You have already subscribed';
      this.successMessage = 'Thank you, you are subscribed now';
      // id of the list of contacts subscribed to the English newsletter
      this.listIds = [6];
      // id of the template of the English email sent when the registration is done
      this.templateId = 13;
    }

    if (this.newsletterForm) {
      fromEvent(this.newsletterForm, 'submit').subscribe((event) => {
        event.preventDefault();
        if (this.formController.isFormValid()) {
          const body = {
            email: this.newsletterForm.email.value,
            list_ids: this.listIds,
          };
          this.formController.sendRequest(
            this.url,
            body,
            this.successMessage,
            this.errorMessage,
          )?.subscribe();
        }
      });
    }
  }
}


