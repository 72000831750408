export class FormValidation {

  currentUrl = window.location.href;
  presenceMessage = 'Ce champ est obligatoire';
  errorEmailMessage = 'Le format de l’adresse mail n’est pas correct (exemple@artra.be)';
  textInputConstraint;
  emailConstraint;
  rgpdConstraint;
  validate = require('validate.js');
  errors: Record<string, string[]> | string[];
  inputs;

  constructor() {
    this.getLangForErrorMessage();
    this.setConstraints();
  }

  isValid() {
    return Object.keys(this.errors).length === 0;
  }

  addMessage(container, type: string, message: string, classToAdd: string) {
    let block = document.createElement('p');
    block.classList.add('help-block');
    block.classList.add(type);
    block.classList.add(classToAdd);
    block.innerHTML = message;
    container.appendChild(block);
  }

  setForm(form: HTMLFormElement, constraints) {
    this.errors = this.validate(form, constraints, { fullMessages: false }) || {};
    this.showErrors(form, this.errors as Record<string, string[]>);
    this.inputs = form.querySelectorAll('input, textarea, select');

    for (let input of this.inputs) {
      input.addEventListener('change', () => {
        this.resetFormGroup(form);
        this.errors = this.validate(form, constraints, { fullMessages: false }) || {};
        this.showErrors(form, this.errors as Record<string, string[]>);
      });
    }
  }

  showErrors(form: HTMLFormElement, errors: Record<string, string[]>) {
    if (errors) {
      form.querySelectorAll('input[name], select[name], textarea[name]').forEach((input: HTMLFormElement) => {
        this.showErrorsForInput(input, errors[input.name]);
      });
    }
  }

  showErrorsForInput(input, errors: string[]) {
    let formGroup = this.closestParent(input.parentNode, 'form-group')
      , messages = formGroup.querySelector('.messages');
    this.resetFormGroup(formGroup);
    if (errors) {
      formGroup.classList.add('has-error');
      errors.forEach(error => {
        this.addMessage(messages, 'error', error, 'validation-error');
      });
    } else {
      formGroup.classList.add('has-success');
    }
  }

  closestParent(child, className: string) {
    if (!child || child == document) {
      return null;
    }
    if (child.classList.contains(className)) {
      return child;
    } else {
      return this.closestParent(child.parentNode, className);
    }
  }

  resetFormGroup(formGroup) {
    formGroup.classList.remove('has-error');
    formGroup.classList.remove('has-success');
    formGroup.querySelectorAll('.help-block').forEach((el) => {
      el.parentNode.removeChild(el);
    });
  }

  getLangForErrorMessage() {
    if (this.currentUrl.includes('en/')) {
      this.presenceMessage = 'This field is required';
      this.errorEmailMessage = 'The email format is not correct (exemple@artra.be)';
    }
  }

  reset(form) {
    form.reset();
  }

  setConstraints() {
    this.textInputConstraint = {
      presence: {
        message: this.presenceMessage,
      },
    };

    this.emailConstraint = {
      presence: {
        message: this.presenceMessage,
      },
      email: {
        message: this.errorEmailMessage,
      },
    };

    this.rgpdConstraint = {
      presence: {
        message: this.presenceMessage,
      },
      inclusion: {
        within: [true],
        message: this.presenceMessage,
      },
    };
  }
}


