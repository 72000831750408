import { ajax } from 'rxjs/ajax';
import { map, Observable, timeout } from 'rxjs';

export class HttpClient {
  /**
   * Default time in ms to wait before returning request timeout error.
   */
  static timeoutTime = 30000;
  static defaultHeaders: Record<string, string> = {
    'Accept': 'application/json',
  };

  static get<T>(url: string, headers: Record<string, string> = {}): Observable<T> {
    return ajax.get<T>(
      url,
      {
        ...headers,
        ...HttpClient.defaultHeaders,
      },
    ).pipe(
      timeout(HttpClient.timeoutTime),
      map((r) => r.response));

  }

  static post<T>(url: string, body?: any, headers: Record<string, string> = {}): Observable<T> {
    return ajax.post<T>(
      url,
      body,
      {
        ...headers,
        ...HttpClient.defaultHeaders,
      },
    ).pipe(
      timeout(HttpClient.timeoutTime),
      map((r) => r.response));
  }
}


