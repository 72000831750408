(() => {
  document.getElementById('hamburger')?.addEventListener('click', (event) => {
    document.getElementsByClassName('top-nav--content').item(0).classList.toggle('open');
    document.getElementById('hamburger').classList.toggle('open');
  });

  document.querySelector('.nav-mobile')?.querySelectorAll('a').forEach((element) => {
    element.addEventListener('click', (event) => {
      document.getElementsByClassName('top-nav--content').item(0).classList.remove('open');
      document.getElementById('hamburger').classList.remove('open');
    });
  });
})();
