interface CCTranslations {
  modalTitle: string;
  modalDescription: string;
  acceptAllLabel: string;
  rejectAllLabel: string;
  settingsModalTitle: string;
  saveSettingsBtn: string;
  closeBtnLabel: string;
}

enum Categories {
  Necessary = 'necessary',
  Analytics = 'analytics',
  Targeting = 'targeting',
}

export class ConsentCookies {
  private translations: Record<string, CCTranslations> = {
    en: {
      modalTitle: 'Your privacy is important to us',
      modalDescription: 'This website uses essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it. The latter will be set only after consent. <button type="button" data-cc="c-settings" class="cc-link">Let me choose</button>',
      acceptAllLabel: 'Accept all',
      rejectAllLabel: 'Reject all',
      settingsModalTitle: 'Cookie preferences',
      saveSettingsBtn: 'Save settings',
      closeBtnLabel: 'Close',
    },
    fr: {
      modalTitle: 'Votre vie privée nous importe',
      modalDescription: 'Ce site utilise des cookies essentiels pour assurer son bon fonctionnement et des cookies de suivi pour comprendre comment vous interagissez avec celui-ci. Ces derniers ne seront activés qu\'après votre accord. <button type="button" data-cc="c-settings" class="cc-link">Configurer</button>',
      acceptAllLabel: 'Accepter tout',
      rejectAllLabel: 'Refuser tout',
      settingsModalTitle: 'Préférences de cookies',
      saveSettingsBtn: 'Sauvegarder',
      closeBtnLabel: 'Fermer',
    },
  };

  constructor() {
    window.addEventListener('load', () => {
      window.cc = initCookieConsent();
      window.im = window['iframemanager']();
      this.run();
    });
  }

  run() {
    window.cc.run({
      current_lang: window.lang || 'en',
      autoclear_cookies: true,                   // default: false
      page_scripts: true,                        // default: false
      // revision: 0,                            // default: 0
      // force_consent: true,                   // default: false
      // hide_from_bots: true,                   // default: true
      // cookie_name: 'cc_cookie',               // default: 'cc_cookie'
      // cookie_expiration: 182,                 // default: 182 (days)
      // cookie_necessary_only_expiration: 182   // default: disabled
      onFirstAction: (user_preferences, cookie) => {
      },
      onAccept: (cookie) => {
        if (cookie.categories.includes(Categories.Analytics)) {
          this.consentAnalyticsCookies(true);
        }
      },
      onChange: (cookie, changed_preferences) => {
        if (changed_preferences.includes(Categories.Analytics)) {
          this.consentAnalyticsCookies(cookie.categories.includes(Categories.Analytics));
        }
      },
      languages: {
        en: this.getConfigurationForLanguage('en'),
        fr: this.getConfigurationForLanguage('fr'),
      },
      gui_options: {
        consent_modal: {
          layout: 'box',
          position: 'bottom right',
        },
      },
    });

    /**
     * IframeManager for youtube video consent
     */
    window.im.run({
      currLang: window.lang || 'en',
      services: {
        youtube: {
          embedUrl: 'https://www.youtube-nocookie.com/embed/{data-id}',
          thumbnailUrl: 'https://i3.ytimg.com/vi/{data-id}/hqdefault.jpg',
          iframe: {
            allow: 'accelerometer; encrypted-media; gyroscope; picture-in-picture; fullscreen;',
          },
          languages: {
            en: {
              notice: 'This content is hosted by a third party. By showing the external content you accept the <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">terms and conditions</a> of youtube.com.',
              loadBtn: 'Load once',
              loadAllBtn: 'Don\'t ask again',
            },
            fr: {
              notice: 'Ce contenu est hébergé par un site tiers. En affichant le contenu externe, vous acceptez les <a rel="noreferrer noopener" href="https://www.youtube.com/t/terms" target="_blank">conditions d\'utilisation</a> de youtube.com.',
              loadBtn: 'Cette fois uniquement',
              loadAllBtn: 'Ne plus demander',
            },
          },
        },
      },
    });
  }

  private consentAnalyticsCookies(consent: boolean) {
    if (consent) {
      window.artra.track.push(['rememberCookieConsentGiven']);
    } else {
      window.artra.track.push(['forgetCookieConsentGiven']);
    }
  }

  private getConfigurationForLanguage(lang: 'en' | 'fr'): LanguageSetting {
    const i18n: CCTranslations = this.translations[lang] || this.translations['en'];
    const text = (en: string, fr?: string): string => {
      return lang === 'en' ? en : fr || en;
    };

    return {
      consent_modal: {
        title: i18n.modalTitle,
        description: i18n.modalDescription,
        primary_btn: {
          text: i18n.acceptAllLabel,
          role: 'accept_all',
        },
        secondary_btn: {
          text: i18n.rejectAllLabel,
          role: 'accept_necessary',
        },
      },
      settings_modal: {
        title: i18n.settingsModalTitle,
        save_settings_btn: i18n.saveSettingsBtn,
        accept_all_btn: i18n.acceptAllLabel,
        reject_all_btn: i18n.rejectAllLabel,
        close_btn_label: i18n.closeBtnLabel,
        cookie_table_headers: [
          { col1: text('From', 'Provenance') },
          { col2: 'Description' },
        ],
        blocks: [
          {
            title: text('Cookie usage', 'Utilisation des cookies'),
            description: text(
              `We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want on <a href="/${lang}/cookies" class="cc-link">the page about cookies.</a>.`,
              `Nous utilisons des cookies pour assurer les fonctionnalités de base du site web et pour améliorer votre expérience en ligne. Vous pouvez choisir pour chaque catégorie de vous inscrire ou de vous désinscrire quand vous le souhaitez sur  <a href="/${lang}/cookies" class="cc-link">la page à propos des cookies</a>.`),
          }, {
            title: text('Strictly necessary cookies', 'Les cookies nécessaires'),
            description: text(
              'These cookies are essential for the proper functioning of this website. Without these cookies, the website would not work properly',
              'Ces cookies sont indispensables au bon fonctionnement de ce site web. Sans ces cookies, le site web ne fonctionnerait pas correctement'),
            toggle: {
              value: Categories.Necessary,
              enabled: true,
              readonly: true,
            },
          }, {
            title: text(
              'Performance and Analytics cookies',
              'Cookies de performance et d\'analyse'),
            description: text(
              'These cookies allow the website to remember the choices you have made in the past',
              'Ces cookies permettent au site web de se souvenir des choix que vous avez faits dans le passé'),
            cookie_table: [
              {
                col1: 'Matomo',
                col2: text(
                  'Matomo is a free web traffic analysis platform.',
                  'Matomo est une plateforme libre d’analyse du trafic sur le web.'),
              },
            ],
            toggle: {
              value: Categories.Analytics,
              enabled: false,
              readonly: false,
            },
          }, {
            title: text(
              'Third-party targeting cookies',
              'Cookies tiers de ciblage'),
            description: text(
              'These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you',
              'Ces cookies collectent des informations sur la façon dont vous utilisez un site web, les pages que vous avez visitées et les liens sur lesquels vous avez cliqué. Toutes les données sont anonymisées et ne peuvent pas être utilisées pour vous identifier'),
            cookie_table: [
              {
                col1: 'facebook.com',
                col2: 'Facebook Messenger',
              },
            ],
            toggle: {
              value: Categories.Targeting,
              enabled: false,
              readonly: false,
            },
          },
        ],
      },
    };
  }
}
