import { debounceTime, fromEvent, map, shareReplay, tap } from 'rxjs';
import scrollIntoView from 'scroll-into-view';

export class HeaderController {
  header: HTMLElement = document.querySelector('.container__logo');
  activeAnchor: string;
  isScrolling = false;
  menuItems: NodeListOf<Element> = document.querySelector('.top-nav').querySelectorAll('a.menu-anchor');
  onScrollStop$ = fromEvent(window, 'scroll').pipe(
    tap(() => this.isScrolling = true),
    // time after the last scroll event we consider scroll is stopped
    debounceTime(100),
    map(() => this.isScrolling = false),
    shareReplay(),
  );

  constructor(disableAnchors = false) {
    this.activeAnchor = this.getAnchorFromUrl();

    if (this.header) {
      this.setMenu();
      fromEvent(window, 'scroll').subscribe(() => {
        this.setMenu();
      });
    }

    /**
     *  Scroll to the section on anchor link click
     */
    if(!disableAnchors){
      fromEvent(document, 'click').subscribe((event) => {
        const target = event.target as HTMLElement;
        const anchor = target.dataset.anchor;
        this.activeAnchor = anchor;
        if (anchor) {
          event.preventDefault();
          this.scrollToActiveSection(anchor);
          this.setActiveLink(anchor);
        }
      });
    }

    // this.scrollToActiveSection(this.activeAnchor, true);
    fromEvent(document, 'readystatechange').subscribe(() => {
      if (this.activeAnchor && document.readyState === 'complete') {
        this.scrollToActiveSection(this.activeAnchor, true);
        this.setActiveLink(this.activeAnchor);
      }
    });
  }

  /**
   * Handle events only when the document is loaded completely
   */
  handleEvents() {
  }

  /**
   *  If there are no anchors in the pages, do nothing
   *  Otherwise get the section from the url
   *  if the section exists, set the menu of the section as active and scroll to it
   *  If the section does not exist, redirect to a 404
   */
  private getAnchorFromUrl(): string {
    return location.pathname.replace(/\/$/, '').split('/').pop();
  }

  /**
   *  Set menu animation when scrolling according to the position on the page
   */
  private setMenu() {
    if (window.scrollY > 51 && window.scrollY < 151) {
      this.header.style.animation = 'position-transition 0.5s 1 normal forwards, bg-transition-back 0.5s 1 normal forwards, height-transition 0.5s 1 normal forwards';
    }

    if (window.scrollY <= 50) {
      this.header.style.animation = 'position-transition-back 0.5s 1 normal forwards, bg-transition-back 0.5s 1 normal forwards, height-transition-back 0.5s 1 normal forwards';
    }

    if (window.scrollY > 150) {
      this.header.classList.add('header-fixed');
      this.header.style.animation = 'bg-transition  0.5s 1 normal forwards, position-transition 0.5s 1 normal forwards, height-transition 0.5s 1 normal forwards';
    }

    if (window.scrollY < 150) {
      if (this.header.classList.contains('header-fixed')) {
        this.header.classList.remove('header-fixed');
        this.header.style.animation = 'bg-transition-back  0.5s 1 normal forwards, position-transition 0.5s 1 normal forwards, height-transition-back 0.5s 1 normal forwards';
      }
    }
  }

  /**
   *  Set the menu items as active (mobile & desktop)
   */
  private setActiveLink(anchor: string) {
    this.menuItems.forEach((menuItem: HTMLElement) => {
      menuItem.classList.remove('active');
      if (menuItem.dataset.anchor === anchor) {
        menuItem.classList.add('active');
      }
    });

    HeaderController.changeUrl(anchor);
  }

  /**
   *  Scroll to the active section according to the active menu item
   */
  private scrollToActiveSection(anchor: string, instant = false) {
    const activeAnchor = document.getElementById(anchor);
    setTimeout(() => {
      scrollIntoView(activeAnchor, {
        time: instant ? 0 : 500,
        align: { top: 0, topOffset: this.header.clientHeight },
      });
    });
  }

  /**
   *  Change the history of the url according to the active menu item
   */
  private static changeUrl(anchor: string) {
    if (anchor === window.lang) {
      return;
    }
    const urlPart = anchor && anchor !== 'home' ? `/${anchor}` : '';
    window.history.replaceState({}, '', `/${window.lang}${urlPart}`);
  }
}
