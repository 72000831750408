import { UserInterface } from '../interfaces/user.interface';
import { v4 } from 'uuid';
import { Product } from './product';
import { ProductOrderData } from '../interfaces/product-order-data.interface';

export class ProductOrder {
  readonly id;
  readonly user: UserInterface;
  readonly product: Product;
  /**
   * Stripe Order URL. Available after stripe was prepared.
   */
  private stripeUrl = '';

  /**
   * Product
   * @param product
   * @param user
   * @param orderId to recreate order from ProductOrderData
   */
  constructor(product: Product, user: UserInterface, orderId?: string) {
    this.id = orderId || v4();
    this.product = product;
    this.user = user;
  }

  get url(): string {
    return this.stripeUrl;
  }

  setStripeUrl(url: string) {
    this.stripeUrl = url;
  }

  export(): ProductOrderData {
    return {
      id: this.id,
      product: this.product.export(),
      user: this.user,
      url: this.url,
    };
  }
}
