import { fromEvent, Observable, Subject } from 'rxjs';

interface RegisterFormDataInterface {
  email: string;
  gallery_name: string;
  gdpr?: 'on';
}

export class RegisterForm {
  /**
   * The register form which appears in the panel
   */
  form: HTMLFormElement;

  private onSubmitSubject = new Subject<RegisterFormDataInterface>();
  private loading = true;
  private messages: NodeListOf<HTMLElement>;
  private inputs: HTMLInputElement[];
  private submitButton: HTMLButtonElement;

  constructor(form: HTMLFormElement) {
    this.form = form;
    this.inputs = [...this.form.querySelectorAll('input, textarea') as  NodeListOf<HTMLInputElement>];
    this.messages = this.form.querySelectorAll('.form-messages > .alert');
    this.submitButton = this.form.querySelector('button[type=submit]');

    fromEvent(this.form, 'submit').subscribe((event) => {
      event.preventDefault();
      if (this.isValid) {
        const formData = Object.fromEntries(new FormData(this.form).entries());
        this.onSubmitSubject.next({
          email: formData.email as string || '',
          gallery_name: formData.gallery_name as string || '',
        });
      }
    });
  }

  get onSubmit$(): Observable<RegisterFormDataInterface> {
    return this.onSubmitSubject.asObservable();
  }

  get isValid(): boolean {
    return this.form.checkValidity();
  }

  get isLoading(): boolean {
    return this.loading;
  }

  reset() {
    this.form.reset();
  }

  setInput(inputName: string, value: string) {
    const input = this.inputs.find((i) => i.getAttribute('name') === inputName);
    input.value = value;
  }

  setLoading(loading: boolean) {
    this.loading = loading;
    if (loading) {
      this.form.classList.add('loading');
      this.submitButton.classList.add('loading');
      this.disable();
    } else {
      this.form.classList.remove('loading');
      this.submitButton.classList.remove('loading');
      this.enable();
    }
  }

  setError(error: 'existent-user' | 'server-error') {
    this.messages.forEach((m) => {
      if (m.dataset.error === error) {
        m.classList.remove('hidden');
      }
    });
  }

  resetErrors() {
    this.messages.forEach((m) => {
      m.classList.add('hidden');
    });
  }

  disable() {
    this.inputs.forEach((i) => i.setAttribute('disabled', ''));
    this.submitButton.setAttribute('disabled', '');
  }

  enable() {
    this.inputs.forEach((i) => i.removeAttribute('disabled'));
    this.submitButton.removeAttribute('disabled');
  }
}
