import { StripeProductInterface } from '../interfaces/stripe-product.interface';
import { ProductOption } from './product-option';

export class Product {
  options: ProductOption[];

  constructor(public data: StripeProductInterface, private locale: string) {
    this.options = Object.keys(this.data.metadata).reduce((accum, property) => {
      if(/^option_\d+$/.test(property)) {
        const [, order] = property.split('_');
        const data = this.data.metadata[property];
        accum.push(new ProductOption(data, parseInt(order), locale));
      }
      return accum;
    }, []);
  }

  get id(): string {
    return this.data.id;
  }

  get active(): boolean {
    return this.data.active;
  }

  get name(): string {
    return this.data.name;
  }

  get price(): number {
    return this.data.default_price.unit_amount / 100;
  }

  get priceId(): string {
    return this.data.default_price.id;
  }

  get order(): number {
    let order = 0;
    if (this.data.metadata.order) {
      order = parseInt(this.data.metadata.order, 10);
    }
    return order > 0 ? order : 0;
  }

  get artworksNumber(): string {
    return this.data.metadata.artworks;
  }

  export(): StripeProductInterface {
    return this.data;
  }
}
