import { catchError, map, mergeMap, Observable, shareReplay, throwError } from 'rxjs';
import { HttpClient } from './httpClient';
import { Product } from '../models/product';
import { StripeProductInterface } from '../interfaces/stripe-product.interface';
import { UserInterface } from '../interfaces/user.interface';
import { PloneStripeUrlResponse } from '../interfaces/plone-stripe-url-response.interface';
import { PloneService } from './plone.service';
import ArtraApp from '../artra-app';
import { AjaxError } from 'rxjs/internal/ajax/errors';
import { ProductOrder } from '../models/product-order';

export class SubscriptionService {
  products: Product[] = [];
  order: ProductOrder | undefined;

  private orderKey = 'ArtraOrder';
  private ploneService: PloneService;
  private initialized = false;

  constructor(private artraApp: ArtraApp) {
    this.ploneService = new PloneService(artraApp);
  }

  getPlanByName(planName: Product['name']): Product | undefined {
    this.logErrorIfNotInitialized();
    return this.products.find((product) => product.name === planName);
  }

  getPlanById(id: Product['id']): Product | undefined {
    this.logErrorIfNotInitialized();
    return this.products.find((product) => product.id === id);
  }

  /**
   * Get all plans from Stripe via Plone request.
   */
  getPlansList() {
    return HttpClient.get<{
      data: StripeProductInterface[]
    }>(this.ploneService.getPloneUrlForRoute('@payment-products')).pipe(
      map(({ data }) => {
        this.products = data
          .map((productData) => new Product(productData, this.artraApp.lang))
          .sort((a, b) => a.order - b.order);
        this.initialized = true;
        return this.products;
      }),
      shareReplay(),
    );
  }

  /**
   * Create Plone user and register plone gallery then call the Plone service to create
   * stripe session url to open to finish subscription.
   */
  createAccount(product: Product, email: string, galleryName: string): Observable<{
    url: string,
    order: ProductOrder
  }> {
    return this.registerPloneUser(email, galleryName).pipe(
      mergeMap((user: UserInterface) => {
        this.order = new ProductOrder(product, user);
        return this.getStripeUrlForOrder(this.order);
      }),
      map(({ url }) => {
        this.order.setStripeUrl(url);
        this.storeOrder(this.order);
        return {
          url,
          order: this.order,
        };
      }),
    );
  }

  restoreOrder(): ProductOrder | undefined {
    const data: string | null = sessionStorage.getItem(this.orderKey);
    if (data) {
      try {
        const orderData = JSON.parse(data);
        const order = new ProductOrder(
          new Product(orderData.product, this.artraApp.lang),
          orderData.user,
          orderData.id,
        );
        order.setStripeUrl(orderData.url);
        return order;
      } catch (e) {
        return undefined;
      }
    }
    return undefined;
  }

  clearOrder() {
    sessionStorage.removeItem(this.orderKey);
  }

  private storeOrder(order: ProductOrder) {
    sessionStorage.setItem(this.orderKey, JSON.stringify(order.export()));
  }

  /**
   * Registers user on plone and returns data
   */
  private registerPloneUser(email: string, galleryName: string): Observable<UserInterface> {
    return this.ploneService.registerUser(email, galleryName, this.artraApp.lang);
  }

  /**
   * PurchaseController the product selected by the client
   * and redirect him according to the state of the purchase
   */
  private getStripeUrlForOrder(order: ProductOrder): Observable<PloneStripeUrlResponse> {
    const body = {
      priceId: order.product.priceId,
      locale: this.artraApp.lang,
      email: order.user.email,
      productId: order.product.id,
      orderId: this.order.id,
      callbackUrl: window.location.href,
    };
    return HttpClient.post<PloneStripeUrlResponse>(this.ploneService.getPloneUrlForRoute('@payment-checkout'), body).pipe(
      catchError((err: AjaxError) => {
        err.message = 'PloneStripeError';
        return throwError(() => err);
      }),
    );
  }

  private logErrorIfNotInitialized() {
    if (!this.initialized) {
      console.error('StripeService is not initialized. No plans available.');
    }
  }
}
