import { ajax } from 'rxjs/ajax';
import { catchError, map, Observable, throwError } from 'rxjs';

/**
 * @deprecated use HttpClient instead.
 */
export class HttpRequestService {
  headers = {
    'Accept': 'application/json',
  };

  /**
   * @deprecated use HttpClient.post() or HttpClient.get() instead.
   * @param url
   * @param method
   * @param token
   * @param body
   */
  request(url: string, method = 'GET', token?: string, body?: object): Observable<any> {
    let headers;

    if (token) {
      headers = { ...this.headers, Authorization: 'Bearer ' + token };
    } else {
      headers = { ...this.headers };
    }

    if (method === 'POST') {
      return HttpRequestService.post(url, body, headers).pipe(
        map((ajaxResponse) => {
          return ajaxResponse.response;
        }),
        catchError(ajaxResponse => throwError(ajaxResponse)),
      );
    } else {
      return HttpRequestService.get(url, headers).pipe(
        map((ajaxResponse) => {
          return ajaxResponse.response;
        }),
        catchError(ajaxResponse => throwError(ajaxResponse)),
      );
    }
  }

  private static get(url: string, headers): Observable<any> {
    return ajax.get(url, headers);
  }

  private static post(url: string, body: object, headers): Observable<any> {
    return ajax.post(url, body, headers);
  }
}
