import { fromEvent } from 'rxjs';

type ClickHandler = (event: MouseEvent, target: HTMLElement) => void;

export class DocumentClickHandler {
  private registeredHandlers: Record<string, ClickHandler> = {};

  constructor() {
    this.listenClicks();
  }

  register(eventName: string, handler: ClickHandler) {
    if (this.registeredHandlers[eventName]) {
      console.error(`Click event for "${eventName}" is already registered.`);
      return;
    }
    this.registeredHandlers[eventName] = handler;
  }

  update(attributeName: string, handler: ClickHandler) {
    this.unregister(attributeName);
    this.register(attributeName, handler);
  }

  unregister(attributeName: string) {
    delete this.registeredHandlers[attributeName];
  }

  private listenClicks() {
    fromEvent(document, 'click').subscribe((event: MouseEvent) => {
      const target: HTMLElement = event.target as HTMLElement;
      const clickEventElement: HTMLElement | null = target.closest('[data-click-event]') ;
      const clickEvent = clickEventElement?.dataset.clickEvent;
      if(clickEvent && this.registeredHandlers[clickEvent]){
        this.registeredHandlers[clickEvent](event, clickEventElement);
      }
    });
  }
}
