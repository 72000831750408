import Swiper, { Pagination } from 'swiper';

(() => {
    const slider = document.getElementsByClassName(".swiper.carousel");
    if (slider) {
        new Swiper(".swiper.carousel", {
            modules: [Pagination],
            spaceBetween: 10,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },
            breakpoints: {
                // when window width is >= 320px
                320: {
                    slidesPerView: 2,
                },
                // when window width is >= 640px
                640: {
                    slidesPerView: 3,
                },
                // when window width is >= 991px
                991: {
                    slidesPerView: 4,
                },
                // when window width is >= 1200px
                1200: {
                    slidesPerView: 5,
                }
            }
        });
    }
})();

